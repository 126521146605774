<template>
  <div>
    <HeaderPanel
      title="Rich Menu"
      :hasFilter="true"
      :filter="filter"
      placeholder="Search Name"
      @search="filterAction"
      :hasDropdown="false"
      routerPath="/setting/rich-menu/0"
    >
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
      >

        <template v-slot:cell(default)="{ item, index }">
          <div>
            <b-form-checkbox
              class=" mt-3 mb-1"
              :id="`checkbox-default-${index}`"
              :name="`checkbox-default-${index}`"
              v-model="item.default"
              :disabled="!item.default"
            >
            </b-form-checkbox>
          </div>
        </template>
        <template v-slot:cell(active)="{ item }">
            <span :class="item.active ? 'text-success' : 'text-error'">{{
              item.active ? "Active" : "Inactive"
            }}</span>
          </template>
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <router-link :to="'/setting/rich-menu/' + item.id">
              <b-button variant="link" class="text-warning px-1 py-0">
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </router-link>
            <div class="d-flex justify-content-center">
              <b-icon
                size="xl"
                icon="trash-fill"
                class="ml-3 icon-size pointer"
              />
            </div>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "w-2 ",
          tdClass: "text-left",
        },
        {
          key: "template",
          label: "Template",
          thClass: "w-1",
        },
        {
          key: "default",
          label: "Default",
          thClass: "w-1",
        },
        {
          key: "active",
          label: "Status",
          class: "w-1",
        },
        {
          key: "action",
          label: "Action",
          class: "w-1",
        },
      ],
      items: [
        { name: "test", template: "TRN", default: "true", active: true },
        { name: "test2", template: "TRN", default: "false", active: false },
      ],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
    };
  },

  async mounted() {
    await this.getList();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getList() {
      //   this.isLoading = true;
      //   const res = await this.axios.post(`/Brand/List`, this.filter);
      //   if (res.data.result === 1) {
      //     this.items = res.data.detail.brands;
      //     this.rows = res.data.detail.totalCount;
      //     this.isLoading = false;
      //   }
    },
    async filterAction() {
      //   this.filter.page = 1;
      //   this.isLoading = true;
      //   const res = await this.axios.post(`/Brand/List`, this.filter);
      //   if (res.data.result === 1) {
      //     this.items = res.data.detail.brands;
      //     this.rows = res.data.detail.totalCount;
      //     this.isLoading = false;
      //   }
      //   this.isLoading = false;
    },

    handleChangeTake(value) {
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
      };
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
